import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  0% {
      transform: translateY(150px);
      opacity: 0;
  }
  100% {
      transform: translateY(-20px);
      opacity: 1;
  }
`

 const Text = styled.div`
  position: absolute; 
  text-transform: uppercase;
  font-size: 18em;
  font-weight: 700;
  opacity: 0;
  color: #f8f8f8;
  animation: ${animation} 2s forwards cubic-bezier(0.2, 0.8, 0.2, 1.0);
  animation-delay: 0.4s;

  @media only screen and (min-width: 375px) and (max-width: 812px) {
  font-size: 5em;
  font-weight: 750;
  margin-top: 1.9em;
  }
`

const BackgroundTextSplit = props => (
  <div>
    <Text>{props.text}</Text>
  </div>
)

export default BackgroundTextSplit