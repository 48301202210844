import React, { Component } from 'react'
import styled, { keyframes } from 'styled-components'
// import Fade from 'react-reveal/Fade'
import Photo1 from '../images/Photo1.png'
import HeroImage from '../components/heroImage'
import BackgroundTextSplit from '../components/backgroundTextSplit.js'
// import { Carousel } from "react-responsive-carousel"
// import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel'
// import 'pure-react-carousel/dist/react-carousel.es.css';

import Layout from "../components/layout"

const ImageContainerAnim = keyframes`
  0% {
    width: 0%;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const ImageAnim = keyframes`
  0% {
    transform: scale(1.4);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`
const slideDown = keyframes`
  0% {
  transform: translateY(-30px);
  opacity: 0;
  }
  100% {
  transform: translateY(0px);
  opacity: 0.4;
  }
`
const LayerReveal = styled.div`
width: 0%;
height: 0%;


`
const Main = styled.div`
  margin: auto;
  width: 70%; 
  height: 60em;

  @media only screen and (min-width: 375px) and (max-width: 812px) {
  width: 90%;
  height: 25em;
  }
`
const SecondaryContainer = styled.div`
  float: left; 
  width: 100%;
  height: 80%; 
  margin: 10em auto; 

  @media only screen and (min-width: 375px) and (max-width: 812px) {
  margin: 10em auto 0em auto;
  }
`
const ImageContainer = styled.div`
  float: left; 
  width: 100%;
  height: 100%; 
  margin: auto;
  opacity: 0;
  background-color: #dee2e4;
  background-opacity: 0;
  animation: ${ImageContainerAnim} cubic-bezier(.12, 1, .25, 1) 2.3s forwards; 
  animation-delay: 0.4s;
  overflow: hidden;
`

const Image = styled.div`
  height:50em;
  background-image: url("https://images.unsplash.com/photo-1554771129-5c8ecd59a14e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=");
  background-size: cover;
 transition: transform 0.4s ease-in-out;
  animation: ${ImageAnim} cubic-bezier(.12, 1, .25, 1) 2.3s;

  &:hover {
    transform: scale(1.03);
  }

`
const ImageSlide = styled.img`
  height:50em;
  background-size: cover;
  transition: transform 0.4s ease-in-out;
  animation: ${ImageAnim} cubic-bezier(.12, 1, .25, 1) 2.3s;

  &:hover {
    transform: scale(1.03);
  }

  @media only screen and (min-width: 375px) and (max-width: 812px) {
  height: 20em;
  }
`

const Text = styled.h1`
  font-family: -apple-system,BlinkMacSystemFont,"Segoe      UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  font-size: 1em;
  font-weight: 400;
  opacity: 0;
  animation: ${slideDown} cubic-bezier(.12, 1, .25, 1) 2.3s forwards; 
  animation-delay: 0.2s;
`

function changeImage() {
            var image = document.getElementById('Image');
            if (image.src.match("colorbottel")) {
                image.src = "waterbottel.gif";
            }
            else {
                image.src = "colorbottel.gif";
            }
        }


export default class Photos extends Component {
	render () {
		return (
    <Layout>
      <div>
      <Main>
      <BackgroundTextSplit text="Photos" />
        <SecondaryContainer>
                <Text>Marin County, California</Text>
                <ImageContainer><ImageSlide src="../../Photo1.png" id="Image" /></ImageContainer>
        </SecondaryContainer>
        <SecondaryContainer>
                <Text>Marin County, California</Text>
                <ImageContainer><ImageSlide src="../../Photo2.jpg" /></ImageContainer>
        </SecondaryContainer>
        <SecondaryContainer>
                <Text>Big Sur, California</Text>
                <ImageContainer><ImageSlide src="../../Photo3.jpg" /></ImageContainer>
        </SecondaryContainer>
        <SecondaryContainer>
                <Text>Santa Monica, California</Text>
                <ImageContainer><ImageSlide src="../../Photo4.jpg" /></ImageContainer>
        </SecondaryContainer>
        <SecondaryContainer>
                <Text>California</Text>
                <ImageContainer><ImageSlide src="../../Photo5.jpg" /></ImageContainer>
        </SecondaryContainer>
        <SecondaryContainer>
                <Text>California</Text>
                <ImageContainer><ImageSlide src="../../Photo6.jpg" /></ImageContainer>
        </SecondaryContainer>
        <SecondaryContainer>
                <Text>Half Dome, Yosemite, California</Text>
                <ImageContainer><ImageSlide src="../../Photo7.JPG" /></ImageContainer>
        </SecondaryContainer>
        <SecondaryContainer>
                <Text>Yosemite Valley, California</Text>
                <ImageContainer><ImageSlide src="../../Photo8.JPG" /></ImageContainer>
        </SecondaryContainer>
        <SecondaryContainer>
                <Text>Mayotte</Text>
                <ImageContainer><ImageSlide src="../../Photo9.jpg" /></ImageContainer>
        </SecondaryContainer>
        <SecondaryContainer>
                <Text>Mayotte</Text>
                <ImageContainer><ImageSlide src="../../Photo10.png" /></ImageContainer>
        </SecondaryContainer>


      </Main>
      <LayerReveal></LayerReveal> 
      </div>
    </Layout>
		)
	}
}